import { auto } from '@popperjs/core';
import IComponent from './IComponent';
import Swiper from 'swiper';
import { Navigation} from 'swiper/modules';


const register = function (): void {
  document.getElementById('dailyNewsletterOffcanvas').addEventListener('shown.bs.offcanvas', function () {
    console.log('Offcanvas shown');
    const swipers = document.querySelectorAll('.newsletter-slider-swiper');

    Array.from(swipers).forEach((swiper: HTMLElement) => {
      console.log('Initializing Swiper for:', swiper);
      const nextBtn: HTMLElement = swiper.querySelector('.newsletter-slider__swiper-button-next');
      const prevBtn: HTMLElement = swiper.querySelector('.newsletter-slider__swiper-button-prev');

      new Swiper(swiper, {
        modules: [Navigation],
        slidesPerView: 2.5,
        spaceBetween: 16,
        navigation: {
          nextEl: nextBtn,
          prevEl: prevBtn,
        },

        touchEventsTarget: 'container',
        preventClicks: true,
        preventClicksPropagation: true,

     
        breakpoints: {
           1200: {
            slidesPerView: 3.5,
            spaceBetween: 16,
          },
        },
      });
    });
  });
};
const newsletterSliderComponent: IComponent = {
  name: 'NewsletterSlider',
  register: register
};

export default newsletterSliderComponent;
